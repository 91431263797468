<template>
  <div>
    <b-modal no-close-on-backdrop :id="id" size="xl" :title="`${this.$route.params.costId ? 'View/Edit Existing' : 'Log New'} Cost`" @hidden="closeModal" body-class="py-0">
      <b-row>
        <b-col class="pt-3" cols="12" v-if="!loading && error">
          <p class="alert alert-danger">{{ error }}</p>
        </b-col>
        <b-col class="py-4 text-center" cols="12" v-if="loading">
          <b-spinner variant="primary"></b-spinner>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col class="modal-body-col py-3" cols="8">
          <b-row class="my-2">
            <b-col cols="10">
              <p class="h5 font-weight-bold">Invoice Items</p>
            </b-col>
            <b-col class="d-flex" cols="2">
              <b-button @click.prevent="editedPropertyCost.items.push({description: '', property_id: null, quantity: 1, unit_price: 1.5})" class="flex-fill" variant="primary"><fa-icon class="mr-2" icon="plus" />Add</b-button>
            </b-col>
          </b-row>
          <b-row class="mb-2" :key="ind" v-for="(item, ind) in editedPropertyCost.items">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Description" :invalid-feedback="getValidationMessage(`items.${ind}.description`)" :state="!validationErrors[`items.${ind}.description`]">
                      <b-form-input type="text" v-model="item.description" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4" class="d-none">
                    <b-form-group label="Quantity" :invalid-feedback="getValidationMessage(`items.${ind}.quantity`)" :state="!validationErrors[`items.${ind}.quantity`]">
                      <b-form-input type="number" v-model="item.quantity" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="8">
                    <b-form-group label="Date of Cost" :invalid-feedback="getValidationMessage(`items.${ind}.costed_at`)" :state="!validationErrors[`items.${ind}.costed_at`]">
                      <b-form-datepicker v-model="item.costed_at" />
                    </b-form-group>
                  </b-col>
                  <b-col class="d-flex flex-column justify-content-end" cols="12" md="4">
                    <b-form-group class="mb-3" label="Unit Price" :invalid-feedback="getValidationMessage(`items.${ind}.unit_price`)" :state="!validationErrors[`items.${ind}.unit_price`]">
                      <b-form-input type="number" min="0.01" step="0.01" v-model="item.unit_price" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="8">
                    <b-form-group label="Property" :invalid-feedback="getValidationMessage(`items.${ind}.property_id`)" :state="!validationErrors[`items.${ind}.property_id`]">
                      <property-search v-model="item.property_id">
                        <template #popover-list="{ result }">
                          <p class="font-weight-bold mb-0">{{ result.name }}</p>
                        </template>

                        <template #selected="{ selected }">
                          <p class="font-weight-bold mb-0">{{ selected.name }}</p>
                        </template>
                      </property-search>
                    </b-form-group>
                  </b-col>
                  <b-col class="d-flex flex-column justify-content-end" cols="6" md="4">
                    <b-button block @click.prevent="editedPropertyCost.items.splice(ind, 1)" class="mb-3" variant="danger"><fa-icon icon="trash" /></b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="border-left bg-light d-flex flex-column justify-items-between modal-body-col py-3" cols="4">
          <b-row>
            <b-col cols="12">
              <p class="h5 font-weight-bold">Uploaded Images</p>
              <b-row>
                <b-col cols="12" :key="ind" v-for="(upload, ind) in editedPropertyCost.files">
                  <b-card body-class="p-1" class="mb-4">
                    <p class="font-weight-bold"><a :href="upload.url">{{ upload.filename }}</a></p>
                    <b-button block @click="editedPropertyCost.files.splice(ind, 1)" variant="danger"><fa-icon icon="trash" /> Delete</b-button>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="border-top border-bottom py-2" cols="12">
              <b-form-file accepts=".gif, .jpg, .png, .heic, .pdf" :disabled="!$route.params.costId" multiple v-model="filesToUpload"></b-form-file>
            </b-col>
            <b-col class="border-top border-bottom mt-3 pt-3" cols="12" v-if="filesToUpload.length > 0">
              <p class="h5 font-weight-bold">Files to Upload</p>
              <p class="mb-2 text-muted"><small>To upload these files, click "Update" below.</small></p>
              <b-card body-class="p-1" class="mb-2" :key="ind" v-for="(file, ind) in filesToUpload">
                <p class="font-weight-bold mb-0">{{ file.name }}</p>
                <p class="text-muted mb-1">{{ (new Intl.NumberFormat('en-GB', { maximumFractionDigits: 1 })).format(file.size / 1024) }} KB</p>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer>
        <p class="text-left text-danger" v-if="validationErrors"><small>{{ validationErrors.bank_account_id && validationErrors.bank_account_id.concat(validationErrors.files || []).join(' ') }}</small></p>
        <div class="d-flex flex-row">
          <label class="align-self-center font-weight-bold mb-0 text-nowrap" for="bank_account">Bank Account</label>
          <b-form-select class="ml-2" id="bank_account" :options="bankAccountsDropdown" v-model="editedPropertyCost.bank_account_id" />
          <b-form-checkbox class="align-self-center ml-4 text-nowrap" v-model="editedPropertyCost.recurring">Recurring, every</b-form-checkbox>
          <b-form-input class="ml-2" :disabled="!editedPropertyCost.recurring" type="number" v-model.number="editedPropertyCost.recurring_value" style="width: 4rem;" />
          <b-form-select class="ml-2" :disabled="!editedPropertyCost.recurring" :options="recurringIntervals"  v-model="editedPropertyCost.recurring_interval"></b-form-select>
          <b-button class="ml-4" @click.prevent="closeModal" variant="light">Close</b-button>
          <b-button class="ml-2" @click.prevent="saveProperty" variant="primary">
            <b-spinner class="mx-4" variant="light" small v-if="saving"/>
            <span v-if="!saving">{{ $route.params.costId ? 'Update' : 'Create' }}</span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PropertySearch from '../../components/PropertySearch.vue';
import handleErrors from '../../mixins/handleErrors';

export default {
  components: { PropertySearch },
  mixins: [handleErrors],
  data() {
    return {
      validationErrors: {},
      genericError: undefined,
      recurringIntervals: [
        { text: 'months', value: 'month' },
        { text: 'weeks', value: 'week' },
        { text: 'days', value: 'day' }
      ],
      filesToUpload: [],
      editedPropertyCost: {
        bank_account_id: null,
        recurring: false,
        recurring_value: 1,
        recurring_interval: 'month',
        items: [{
          property_id: null,
          description: '',
          unit_price: 1.50,
          quantity: 1,
        }]
      },
      loading: false,
      saving: false,
      error: '',
    };
  },
  emit: ['hidden'],
  props: {
    cost: Object,
    id: String,
  },
  computed: {
    ...mapState('bankAccounts', { bankAccounts: 'data' }),
    bankAccountsDropdown() {
      return [{
        disabled: true,
        value: null,
        text: 'Select One...',
      }, ...this.bankAccounts.map(ba => ({
        text: ba.name,
        value: ba.id,
      }))]
    },
  },
  async mounted() {
    this.fetchBankAccounts();
    if (this.$route.params.costId && this.cost) {
      this.loading = true;

      try {
        const propertyCost = await this.fetchSingle(this.$route.params.costId);
        const intSplit = propertyCost.interval?.split(' ')

        this.editedPropertyCost = {
          ...propertyCost,
          recurring: propertyCost.interval !== null,
          recurring_value: parseInt((intSplit?.length ?? 0) > 0 ? intSplit[0] : '1'),
          recurring_interval: (intSplit?.length ?? 0) > 1 ? intSplit[1] : 'month',
        };
        this.loading = false;
      } catch (err) {
        this.error = "Can not load property cost. Please try again later.";
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions('bankAccounts', { fetchBankAccounts: 'fetch' }),
    ...mapActions('costs', ['fetch', 'fetchSingle', 'updateOrCreate']),
    closeModal() {
      this.editedPropertyCost = {
        bank_account_id: null,
        recurring: false,
        recurring_value: 1,
        recurring_interval: 'month',
        items: [{
          property_id: null,
          description: '',
          unit_price: 1.50,
          quantity: 1,
        }]
      };
      this.validationErrors = {};
      this.filesToUpload = [];
      if (this.$route.name !== this.$route.meta.parentName) {
        this.$router.push({ name: this.$route.meta.parentName });
      }
      this.$emit('hidden');
    },
    saveProperty() {
      this.saving = true;
      this.uploadFiles().then(() =>
        this.updateOrCreate(this.editedPropertyCost).then(() => {
          this.saving = false;
          this.closeModal();
          return this.fetch();
        })
      ).catch(err => {
        this.saving = false;
        if (err.response && err.response.status === 422) {
          this.saving = false;
          this.handleValidationErrors(err);
        } else {
          this.saving = false;
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      });
    },
    async uploadFiles() {
      const formData = new FormData();
      this.filesToUpload.map(file =>
        formData.append('files[]', file, file.name)
      );

      if (this.filesToUpload.length) {
        const response = await window.apiFetch(`/costs/${this.$route.params.costId}/upload`, {
          method: 'POST',
          body: formData,
        })
        const uploads = await response.json();
        this.editedPropertyCost.files = [
          ...this.editedPropertyCost.files,
          ...uploads
        ];
      }

      return;
    }
  },
  watch: {
    cost(value) {
      const intSplit = value.interval?.split(' ')

      this.editedPropertyCost = {
        ...value,
        recurring: value.interval !== null,
        recurring_value: parseInt((intSplit?.length ?? 0) > 0 ? intSplit[0] : '1'),
        recurring_interval: (intSplit?.length ?? 0) > 1 ? intSplit[1] : 'month',
      };
    }
  }
}
</script>

<style>
.modal-body-col {
  height: 75vh;
  overflow: auto;
}
</style>
